@import "lib/variables.scss";

.wrapper {
    background: $blue_to_green_gradient;
    height: calc(100vh - 8rem);
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.screensaverWelcomeText {
    margin: 1rem 0 0 0;
    font-size: 2.7rem;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.8);
}

.screensaverInstructions {
    margin: 1rem 0 0 0;
    font-weight: bold;
    font-size: 1.5rem;
    color: rgba(255, 255, 255, 0.8);
}

.screensaverCircleOuter {
    margin-top: 2.2rem;
    width: 22rem;
    height: 22rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.25);
    border-radius: 50%;
}

.screensaverCircleInner {
    width: 13rem;
    height: 13rem;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.3);
    font-weight: bold;
    font-size: 2rem;
    text-transform: uppercase;
    color: rgba(#2794dc, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.screensaverClockAndLanguageBar {
    margin-top: 2.3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 4rem);
}

.screensaverPointingHandWrapper {
    position: absolute;
    top: 10rem;
}

.screensaverPointingHand {
    position: absolute;
    top: -0.2rem;
    left: -3.5rem;
    z-index: 3;
    animation: pulseSize 2.5s infinite;
    filter: drop-shadow(0px 5px 15px rgba(100, 100, 111, 0.2));
    height: 10rem;
}

.screensaverPointingHandWrapperPulse {
    width: 1px;
    height: 1px;
    border-radius: 50%;
    box-shadow: 0 0 0 rgba(#ffffff, 0.4);
    animation: pulseColor 2.5s infinite;
}

.screensaverQRCode {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-2rem, -50%);
    width: 10rem;
    height: 10rem;
    filter: drop-shadow(0px 5px 15px rgba(100, 100, 111, 0.2));
}

@keyframes pulseColor {
    0% {
        box-shadow: 0 0 0 0 rgba(#ffffff, 0.4);
    }
    50% {
        box-shadow: 0 0 0 160px rgba(#ffffff, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(#ffffff, 0);
    }
}

@keyframes pulseSize {
    0% {
        transform: scale(1);
    }
    70% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}
