@import '../../lib/variables.scss';

.wrapper {
    height: calc(100vh - 8rem);
    background: $blue_to_green_gradient;
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    user-select: none;
}

.checkInSuccessfulHeaderText {
    margin: 1rem 0 0 0;
    font-size: 1.3rem;
    color: rgba(#ffffff, 0.8);
    text-transform: uppercase;
    @media only screen and (min-width: 768px) {
        font-size: 2.7rem;
    }
}

.checkInSuccessfulStepsText {
    margin: 1rem 0 0 0;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    color: rgba(#ffffff, 0.8);
    @media only screen and (min-width: 768px) {
        font-size: 1.5rem;
        margin: 1rem 0 0 0;
    }
}

.checkInSuccessfulIconRow {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 2rem;
    @media only screen and (min-width: 768px) {
        gap: 4rem;
        margin-top: 4rem;
    }
}

.checkInSuccessfulIconWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    font-size: 1rem;
    color: rgba(#ffffff, 0.8);
    @media only screen and (min-width: 768px) {
        font-size: 1.5rem;
    }
}

.checkInSuccessfulIcon {
    width: 9rem;
    height: 9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.1);
    border: 0.15rem solid #dfdfdf;
    box-sizing: border-box;
    border-radius: 1.5rem;
    @media only screen and (min-width: 768px) {
        width: 15rem;
        height: 15rem;
        border-radius: 2rem;
    }
}

.checkInSuccessfulIconImage {
    height: 70%;
    width: 70%;
}

.checkInSuccessfulQRCode {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-2rem, -50%);
    width: 10rem;
    height: 10rem;
    filter: drop-shadow(0px 5px 15px rgba(100, 100, 111, 0.2));
}

.checkInSuccessfulNewCheckInButton {
    padding: 1rem 1.7rem;
    margin-top: 4rem;
    border-radius: 3rem;
    align-self: center;
    font-weight: bold;
    font-size: 1rem;
    background: $transparent_white;
    color: white;
    border: 0.15rem solid #dfdfdf;
    &:active {
        opacity: 50%;
    }
    @media only screen and (min-width: 768px) {
        padding: 1rem 1.7rem;
        border-radius: 3rem;
        font-size: 1.8rem;
    }
}
.checkInSuccessfulMessageText {
    margin: 1rem 2rem 0 2rem;
    line-height: 2rem;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    color: rgba(#ffffff, 0.8);
    @media only screen and (min-width: 768px) {
        font-size: 1.5rem;
        margin: 1rem 0 0 0;
    }
}
