@import '../../lib/variables.scss';

.wrapper {
    height: calc(100vh - 8rem);
    background: $blue_to_green_gradient;
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
}

.surveyHeaderText {
    margin: 2rem 0 0 0;
    font-size: 2.5rem;
    color: rgba(#ffffff, 0.8);
    text-transform: uppercase;
    @media only screen and (min-width: 768px) {
        font-size: 2.7rem;
        margin: 1rem 0 0 0;
    }
}

.surveyMessageText {
    margin: 1rem 2rem 0 2rem;
    line-height: 2rem;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    color: rgba(#ffffff, 0.8);
    @media only screen and (min-width: 768px) {
        font-size: 1.5rem;
        margin: 1rem 0 0 0;
    }
}
.surveyStepsText {
    margin: 1rem 2rem 0 2rem;
    line-height: 2rem;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    color: rgba(#ffffff, 0.8);
    @media only screen and (min-width: 768px) {
        font-size: 1.5rem;
        margin: 2rem 0 0 0;
    }
}

.surveyIconsRow {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 2rem;
    @media only screen and (min-width: 768px) {
        gap: 3rem;
    }
}

.surveyIconWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    font-size: 1rem;
    color: rgba(#ffffff, 0.8);
    @media only screen and (min-width: 768px) {
        font-size: 1.5rem;
    }
}

.surveyIcon {
    width: 9rem;
    height: 9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.1);
    border: 0.15rem solid #dfdfdf;
    box-sizing: border-box;
    border-radius: 1.5rem;
    @media only screen and (min-width: 768px) {
        width: 12rem;
        height: 12rem;
    }
}

.surveySuccessfulNewCheckInButton {
    padding: 1rem 1.7rem;
    margin-top: 4rem;
    border-radius: 3rem;
    align-self: center;
    font-weight: bold;
    font-size: 1rem;
    background: $transparent_white;
    color: white;
    border: 0.15rem solid #dfdfdf;
    &:active {
        opacity: 50%;
    }
    @media only screen and (min-width: 768px) {
        padding: 1rem 1.7rem;
        margin-top: 4.7rem;
        border-radius: 3rem;
        font-size: 1.8rem;
    }
}

.surveyIconImage {
    height: 70%;
    width: 70%;
}
