.container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-size: large;
}

.spinner {
    animation: spin 1s linear infinite;
    height: 1rem;
    width: 1rem;
    margin: 0rem 0.5rem;
    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
}

.opacity75 {
    opacity: 0.75;
}

.opacity25 {
    opacity: 0.25;
}
