@import '../../lib/variables.scss';

.surveyNumberInput {
    font-size: 1rem;
    width: 50%;
    margin: 1rem 0 0 0;
    outline: none;
    padding: 0.3rem;
    border-radius: 0.4rem;
    border-style: solid;
    @media only screen and (min-width: 768px) {
        font-size: 1.3rem;
        margin: 1rem 0 0 0;
    }
}

.surveyInputLabel {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media only screen and (min-width: 768px) {
        font-size: 1rem;
    }
}
