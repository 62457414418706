@import '../../lib/variables.scss';

.survey {
    height: calc(100vh - 8rem);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.surveyTitleText {
    font-weight: 700;
    font-size: 1.3rem;
    text-transform: uppercase;
    margin: 1.8rem 2rem 0 2rem;
    text-align: center;
    @media only screen and (min-width: 768px) {
        font-size: 1.8rem;
        margin: 2.8rem 0 0 0;
    }
}
