.wrapper {
  display: flex;
  width: max-content;
  height: max-content;
  border: 1px solid #FFFFFF;
  border-radius: 3rem;
  padding: 1rem;
  background-color: rgba(#FFFFFF, 0.15);
}

.language {
  width: 2.5rem;
  height: 2.5rem;
  min-width: auto;
  margin: 0 .625rem;
  padding: 0;
  border-radius: 50%;
  border: none;
}

.iconDisabled {
  max-width: 100%;
  filter: grayscale(70%);
}

.iconActive {
  max-width: 100%;
}