@import '../../lib/variables.scss';

.clock {
    background: white;
    color: hsl(204, 72%, 51%);
    display: flex;
    height: 4.5rem;
    font-weight: 700;
    font-size: 1.6rem;
    gap: 0.3rem;
    padding: 0 1.5rem;
    border-radius: 3rem;
    align-items: center;
    user-select: none;
}

.clockDateText {
    color: grey;
}
