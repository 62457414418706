@import '../../lib/variables.scss';

.wrapper {
    height: 8rem;
    display: flex;
    align-items: center;
    border-bottom: 0.5rem solid;
    border-top: 0;
    border-right: 0;
    border-left: 0;
}

.withBackground {
     border-image-source: $transparent_blue_to_green_gradient;
    border-image-slice: 1;
}

.withoutBackground {
    background: $transparent_white;
    border-image-source: $blue_to_green_gradient;
    border-image-slice: 1;
}

.logo {
    margin-left: 6rem;
    max-height: 50%;
}