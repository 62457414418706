@import '../../lib/variables.scss';

.checkIn {
    height: calc(100vh - 8rem);
    display: flex;
    align-items: center;
    flex-direction: column;
    color: $dark_grey;
    user-select: none;
}

.checkInText {
    font-weight: 700;
    font-size: 1.3rem;
    text-transform: uppercase;
    text-align: center;
    margin: 1.8rem 0 0 0;
    @media only screen and (min-width: 768px) {
        font-size: 1.8rem;
        margin: 2.8rem 0 0 0;
    }
}

.checkInButton {
    padding: 1rem 1.5rem;
    border: 0;
    margin-top: 1.5rem;
    border-radius: 3rem;
    font-weight: bold;
    font-size: 1.2rem;
    background: $blue_to_green_gradient;
    color: white;
    &:disabled {
        background: $light_grey;
    }
    &:active {
        background: $transparent_blue_to_green_gradient;
    }
    @media only screen and (min-width: 768px) {
        font-size: 1.8rem;
        padding: 1.3rem 2rem;
    }
    user-select: none;
}

.errorColor {
    border-color: #e31c40;
    color: #e31c40;
}

.errorMessage {
    font-size: 0.8rem;
    height: 1rem;
    margin: 1rem 0 0 0;
}

.securityNumberContainer {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.4rem;
    border: 1px solid #cccccc;
    padding-left: 4px;
}
.secNumInitials {
    font-size: 20px;
    margin: 0;
}
.securityNumberInput {
    font-size: 19px;
    width: 90%;
    outline: none;
    padding: 0.3rem 0px;
    border: 0px;
}
.securityNumberInput::placeholder {
    font-size: 1.5rem;
    color: #e1e2e2;
}
.securityNumberInput::-webkit-input-placeholder {
    font-size: 1.5rem;
    color: #e1e2e2;
}
.securityNumberInput:-moz-placeholder {
    font-size: 1.5rem;
    color: #e1e2e2;
}
.securityNumberInput::-moz-placeholder {
    font-size: 1.5rem;
    color: #e1e2e2;
}
.securityNumberInput:-ms-input-placeholder {
    font-size: 1.5rem;
    color: #e1e2e2;
}
.securityNumberInput::-ms-input-placeholder {
    font-size: 1.5rem;
    color: #e1e2e2;
}
